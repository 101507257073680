import isString from './isString'

/**
 * Checks if a passed value is a string
 * and if it is not blank.
 *
 * ```js
 * import isPresent from '@vayapin/isPresent'
 *
 * isPresent(undefined) // false
 * isPresent('') // false
 * isPresent('x') // true
 * ```
 */
function isPresent(value: unknown): value is string {
  return isString(value) && value.length > 0
}

export default isPresent
